import React, { useEffect } from 'react'
import { RiFileExcel2Fill } from 'react-icons/ri'
import { SiGooglesheets, SiPython, SiReact, SiJavascript, SiHtml5, SiTailwindcss, SiFirebase, SiExpress, SiMongodb, SiNpm, SiJson } from 'react-icons/si'
import { AiTwotoneHome, AiFillLinkedin } from 'react-icons/ai'
import { MdOutlineMail, MdSportsBasketball } from 'react-icons/md'
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Contact = () => {

  const controls = useAnimation();
  const [ref, inView] = useInView();

  const animation = {
    hidden: { y: 50, opacity: 0, scale: 1 },
    visible: {
      y: 0,
      transition: {
        delay: 0.1,
        duration: 2,
        type: 'spring',
      },
      opacity: 1,
    },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    //} else {
    //  controls.start("hidden");
    }
  }, [controls, inView]);

  return (

    <motion.div
      className='flex justify-center min-h-screen w-full container'
      id='contact'
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={animation}
    >
      <div className='flex flex-col justify-around'>
        <p className='uppercase font-bold md:text-6xl text-4xl text-center'>Contact Me</p>

        <div className='flex flex-col items-center'>

          <div className='flex items-center md:mb-10 mb-5'>
            <MdOutlineMail className='md:text-4xl text-xl' color='red' />
            <a href='mailto:gilbert@gcconsultancy.ca' className='text-blue-500 ml-4'>E-mail: gilbert@gcconsultancy.ca</a>
          </div>

          <div className='flex items-center md:mb-10 mb-5'>
            <AiTwotoneHome className='md:text-4xl text-xl' color='purple' />
            <a href='https://www.gcconsultancy.ca/' target="_blank" rel="noreferrer" className='text-blue-500 ml-4'>www.gcconsultancy.ca</a>
          </div>

          <div className='flex items-center md:mb-10 mb-5'>
            <MdSportsBasketball className='md:text-4xl text-xl' color='darkorange' />
            <a href='https://www.gcconsultancy.ca/portfolio_sports/' target="_blank" rel="noreferrer" className='text-blue-500 ml-4'>Sports Betting Tools Portfolio</a>
          </div>

          <div className='flex items-center md:mb-10 mb-5'>
            <AiFillLinkedin className='md:text-4xl text-xl' color='blue' />
            <a href='https://www.linkedin.com/in/gilbert-chang-61578143/' target="_blank" rel="noreferrer" className='text-blue-500 ml-4'>Linkedin</a>
          </div>

        </div>

        <div className=''>
          <p className='text-center text-md font-bold mb-4'>Technical Skills</p>
          < div className='flex md:w-full flex-wrap justify-center' >
            <RiFileExcel2Fill color='green' className='md:text-5xl text-2xl md:mr-4 mr-1' />
            <SiGooglesheets color='#3CB371' className='md:text-5xl text-2xl md:mr-4 mr-1' />
            <SiPython color='orange' className='md:text-5xl text-2xl md:mr-4 mr-1' />
            <SiReact color='blue' className='md:text-5xl text-2xl md:mr-4 mr-1' />
            <SiJavascript color='orange' className='md:text-5xl text-2xl md:mr-4 mr-1' />
            <SiExpress color='black' className='md:text-5xl text-2xl md:mr-4 mr-1' />
            <SiHtml5 color='darkorange' className='md:text-5xl text-2xl md:mr-4 mr-1' />
            <SiTailwindcss color='aqua' className='md:text-5xl text-2xl md:mr-4 mr-1' />
            <SiFirebase color='orange' className='md:text-5xl text-2xl md:mr-4 mr-1' />
            <SiMongodb color='green' className='md:text-5xl text-2xl md:mr-4 mr-1' />
            <SiJson color='black' className='md:text-5xl text-2xl md:mr-4 mr-1' />
            <SiNpm color='red' className='md:text-5xl text-2xl' />
          </div >
        </div>

      </div>
    </motion.div>


  )
}

export default Contact