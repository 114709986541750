import React, { useEffect } from 'react'
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import pic from "../assets/pic.jpg"

const Main = () => {

  const controls = useAnimation();
  const [ref, inView] = useInView();


  const variants1 = {
    //visible: { x: [0, 0, 0], opacity: 1, scale: 1 },
    hidden: { y: 50, opacity: 0, scale: 1 },
    visible: {
      y: 0,
      transition: {
        delay: 0.1,
        duration: 2,
        type: 'spring',
      },
      opacity: 1,
    },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    //} else {
    //  controls.start("hidden");
    }
  }, [controls, inView]);


  return (
    <div className='flex min-h-screen items-center' id='main'>
      <motion.div
        className='md:flex flex-row w-full items-center'
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={variants1}
        viewport={{ once: true }}
      >

        <div className='flex justify-center md:mx-16 mx-auto md:w-1/2 md:mb-0 mb-20'>
          <img className='md:h-64 h-48 md:w-64 w- w-48 rounded-full pointer-events-none border-8 border-gray-300 border-opacity-20' src={pic} alt=''></img>
        </div>


        <div className='flex flex-col justify-center md:mx-16 mx-auto md:w-1/2'>
          <p className='md:text-5xl text-3xl uppercase font-bold md:text-left text-center' >Gilbert Chang</p>
          <p className='md:text-xl text-md uppercase md:tracking-title tracking-widest md:text-left text-center text-rose-600 font-extrabold'>Technical Consultant</p>
          <p className='md:px-0 px-10 md:text-base text-sm md:text-left text-center mt-3'>
            My goal is to identify areas for improvements for businesses, then effectively and efficiently provide innovative solutions for my clients.
          </p>
          <div className='mt-6 flex md:justify-start justify-center animate-bounce md:px-0 px-10'>
            <a href='mailto:gilbert@gcconsultancy.ca' className='bg-rose-400 hover:bg-rose-600 px-6 py-1 rounded shadow-2xl text-white  transform hover:scale-x-105 hover:scale-y-105 transition duration-300 ease-out'>Contact me now!</a>
          </div>

        </div>

      </motion.div>
    </div >
  )
}

export default Main