import Navbar from "./components/NavBar";
import { Home } from "./pages/Home";
import Footer from "./Sections/Footer";


function App() {
  return (
    <div className="">
      <div className="sticky top-0 z-20">
        <Navbar />
      </div>

      <Home />
      <Footer />
    </div>
  );
}

export default App;
