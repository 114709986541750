
import React, { createContext, useContext } from 'react'

const ContextState = createContext()

const ContextAPI = ({ children }) => {

    const test = 'hello'
    
    return (
        <ContextState.Provider value={{ test }}>
            {children}
        </ContextState.Provider>
    )
}

export default ContextAPI

export const useContextAPI = () => useContext(ContextState)