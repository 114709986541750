import React from 'react'


const Footer = () => {
    return (

        < div className='container mx-auto' >
            <div>
                <p className='text-xs text-center py-4'>&copy; 2023 Gilbert Chang</p>
            </div>
        </div >
    )
}

export default Footer