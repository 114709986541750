import React from 'react'

const buttonClick = () => {
    const menu = document.querySelector('.mobile-menu')
    menu.classList.toggle("hidden")
}

const Navbar = () => {
    return (

        <nav className=''>
            <div className='container mx-auto border-b-2 border-b-black bg-white'>
                <div className='flex justify-between py-4'>
                    <div className='flex space-x-2'>
                        {/* logo */}
                        <div>
                            <a href="#main" className='flex items-center px-2'>
                                <span className='font-bold uppercase'>Gilbert Chang</span>
                            </a>
                        </div>
                    </div>

                    {/* secondary nav */}
                    <div className='hidden md:flex items-center'>
                        <a href="#services" className='hover:underline uppercase font-bold text-sm mr-4'>Services</a>
                        <a href="#contact" className='hover:underline uppercase font-bold text-sm'>Contact Me</a>
                    </div>

                    {/* mobile button */}
                    <div className='md:hidden flex items-center mr-2'>
                        <button className='mobile-menu-button' onClick={buttonClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </button>
                    </div>

                </div>


            </div>
            {/* mobile menu */}
            <div className='mobile-menu hidden border-b'>
                <a href="#services" className='block py-2 px-4 text-sm hover:bg-gray-200 hover:underline' onClick={buttonClick}>Services</a>
                <a href="#contact" className='block py-2 px-4 text-sm hover:bg-gray-200 hover:underline' onClick={buttonClick}>Contact Me</a>
            </div>

        </nav>

    )
}

export default Navbar