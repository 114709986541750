import React, { useEffect } from 'react'
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import excel from '../assets/excel.gif'
import gsheets from '../assets/gsheets.gif'
import gcconsult from '../assets/gcconsult.gif'
import sports from '../assets/sports.gif'
import python from '../assets/python.gif'


const Services = () => {

    const controls = useAnimation();
    const controls2 = useAnimation();
    const controls3 = useAnimation();
    const controls4 = useAnimation();
    const controls5 = useAnimation();
    const [ref, inView] = useInView();
    const [ref2, inView2] = useInView();
    const [ref3, inView3] = useInView();
    const [ref4, inView4] = useInView();
    const [ref5, inView5] = useInView();

    const variantImage = {
        //visible: { opacity: 1, scale: 1, transition: { delay: 0, duration: 0.7 } },
        hidden: {
            y: 100,
            opacity: 0,
            scale: 0.5,
            transition: {
                delay: 0,
                duration: 1,
            },
        },
        visible: {
            y: 0,
            opacity: 1,
            scale: 1,
            transition: {
                delay: 0,
                duration: 1,
                //type: 'spring',
            },
        },
    };



    useEffect(() => {
        if (inView) {
            controls.start("visible");
        //} else {
        //    controls.start("hidden");
        }
    }, [controls, inView]);

    useEffect(() => {
        if (inView2) {
            controls2.start("visible");
        //} else {
        //    controls2.start("hidden");
        }
    }, [controls2, inView2]);

    useEffect(() => {
        if (inView3) {
            controls3.start("visible");
        //} else {
        //    controls3.start("hidden");
        }
    }, [controls3, inView3]);

    useEffect(() => {
        if (inView4) {
            controls4.start("visible");
        //} else {
        //    controls4.start("hidden");
        }
    }, [controls4, inView4]);

    useEffect(() => {
        if (inView5) {
            controls5.start("visible");
        //} else {
        //    controls5.start("hidden");
        }
    }, [controls5, inView5]);


    return (
        <div className='flex min-h-screen items-center justify-center pt-16' id='services'>
            <div className='flex flex-col justify-center w-full'>
                <p className='text-center uppercase font-bold md:text-6xl text-4xl mb-40 w-full' >Services</p>
                <div className='w-full'>
                    <div className='mb-40 flex md:flex-row-reverse flex-col items-center justify-between md:px-20 px-0 w-full'>
                        <motion.img className='md:w-5/12 w-10/12 md:mb-6 mb-0 rounded-lg border-2 border-slate-800 pointer-events-none' src={gcconsult} alt=''
                            ref={ref}
                            animate={controls}
                            initial="hidden"
                            variants={variantImage}
                        ></motion.img>
                        <div className='flex flex-col md:w-5/12 w-10/12 md:mt-0 mt-4'>
                            <p className='md:text-4xl text-base font-bold w-auto text-center'>Full Stack Web Dev</p>
                            <p className='w-auto md:text-sm text-xs text-center'>React, Javascript, Express, JSON, HTML/CSS</p>
                        </div>
                    </div>

                    <div className='mb-40 flex md:flex-row flex-col items-center justify-between md:px-20 px-0 w-full'>
                        <motion.img className='md:w-5/12 w-10/12 md:mb-6 mb-0 rounded-lg border-2 border-slate-800 pointer-events-none' src={python} alt=''
                            ref={ref2}
                            animate={controls2}
                            initial="hidden"
                            variants={variantImage}
                        ></motion.img>
                        <div className='flex flex-col md:w-5/12 w-10/12 md:mt-0 mt-4'>
                            <p className='md:text-4xl text-base font-bold w-auto text-center'>Python</p>
                            <p className='w-auto md:text-sm text-xs text-center'>Software Dev, Data Mining, Web Scrape</p>
                        </div>
                    </div>

                    <div className='mb-40 flex md:flex-row-reverse flex-col items-center justify-between md:px-20 px-0 w-full'>
                        <motion.img className='md:w-5/12 w-10/12 md:mb-0 mb-6 rounded-lg border-2 border-slate-800 pointer-events-none' src={gsheets} alt=''
                            ref={ref3}
                            animate={controls3}
                            initial="hidden"
                            variants={variantImage}
                        ></motion.img>
                        <div className='flex flex-col md:w-5/12 w-10/12 md:mt-0 mt-4'>
                            <p className='md:text-4xl text-base font-bold w-auto text-center'>Google Sheets</p>
                            <p className='w-auto md:text-sm text-xs text-center'>Apps Script, API, JSON</p>
                        </div>
                    </div>

                    <div className='mb-40 flex md:flex-row flex-col items-center justify-between md:px-20 px-0 w-full'>
                        <motion.img className='md:w-5/12 w-10/12 md:mb-6 mb-0 rounded-lg border-2 border-slate-800 pointer-events-none' src={excel} alt=''
                            ref={ref4}
                            animate={controls4}
                            initial="hidden"
                            variants={variantImage}
                        ></motion.img>
                        <div className='flex flex-col md:w-5/12 w-10/12 md:mt-0 mt-4'>
                            <p className='md:text-4xl text-base font-bold w-auto text-center'>Excel</p>
                            <p className='w-auto md:text-sm text-xs text-center'>VBA, Automation, Macro Developement</p>
                        </div>
                    </div>


                    <div className='mb-40 flex md:flex-row-reverse flex-col items-center justify-between md:px-20 px-0 w-full'>
                        <motion.img className='md:w-5/12 w-10/12 md:mb-6 mb-0 rounded-lg border-2 border-amber-200 pointer-events-none' src={sports} alt=''
                            ref={ref5}
                            animate={controls5}
                            initial="hidden"
                            variants={variantImage}
                        ></motion.img>
                        <div className='flex flex-col md:w-5/12 w-10/12 md:mt-0 mt-4'>
                            <p className='md:text-4xl text-base font-bold w-auto text-center'>Sports Betting Tools</p>
                            <p className='w-auto md:text-sm text-xs text-center text-blue-500 underline'><a href='https://www.gcconsultancy.ca/portfolio_sports/' target="_blank" rel="noreferrer">gcconsultancy.ca/sports_portfolio</a></p>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Services